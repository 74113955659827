import React from 'react'
import { Link } from 'gatsby'
import logo from '../../../content/images/logo.png'
import './style.scss'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Icon from '../Icon/index'

class Navi extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showNavMobile: false,
    }
  }

  toggleNav = () => {
    this.setState({
      showNavMobile: !this.state.showNavMobile,
    })
  }

  render() {
    const { location, title } = this.props
    return (
      <div className="bg-light">
        <div className="container-fluid">
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Navbar.Brand href="/">
              <img
                src={logo}
                alt="logo" 
                className="logo d-inline-block align-to"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                <Link className="nav-link" to="/#o-nas">
                  O nas
                </Link>
                <Link className="nav-link" to="/zakres-uslug/">
                  Zakres usług
                </Link>
                <Link className="nav-link" to="/cennik/">
                  Cennik
                </Link>
                <Link className="nav-link" to="/aktualnosci/">
                  Aktualności
                </Link>
                <Link className="nav-link" to="/kontakt/">
                  Kontakt
                </Link>
                <a className="nav-link fb-link" href="https://www.facebook.com/promotaxbiurorachunkowe/">
                  <Icon name="facebook"/>
                </a>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    )
  }
}

export default Navi
