import { graphql, Link } from 'gatsby'
import React, {lazy, Suspense} from 'react'
import get from 'lodash/get'
import loadable from '@loadable/component'

import Post from 'templates/Post'
import Meta from 'components/Meta'
import Layout from 'components/Layout'
import Img from 'gatsby-image'
import { Parallax } from 'react-scroll-parallax'
import ArticleCarousel from 'components/ArticleCarousel/ArticleCarousel'
const GoogleMap = loadable(() => import('components/Map/Map'))
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Icon from '../components/Icon/index';

import serviceImg1 from '../../content/images/services/kadry_i_place-01.png'
import serviceImg2 from '../../content/images/services/ksiegowosc2-01.png'
import serviceImg3 from '../../content/images/services/pelne_wsparcie-01.png'
import serviceImg4 from '../../content/images/services/doradztwo_biznesowe4-01.png'
import serviceImg5 from '../../content/images/services/doradztwo_podatkowe5-01.png'
import finances from '../../content/images/finances.svg'

const BlogIndex = ({ data, location }) => {
  const posts = get(data, 'remark.posts')
  const imageSizes = data.allFile.edges.reduce((images, item) => {
    images[item.node.name] = item.node.childImageSharp.fluid
    return images
  }, {})
  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} description="PROMO TAX to nowoczesne biuro rachunkowe z siedzibą w Katowicach. Nieustanie wychodzimy naprzeciw potrzebom Twojego biznesu. Wejdź i pozwól nam zadbać o Twoje finanse!" />
      <div className="hero-img-wrapper">
        <Parallax className="hero-img" y={[-20, 20]}  tagOuter="figure">
          <Img fluid={imageSizes['fot-3']} />
        </Parallax>
        <div className="hero-box">
          <h1>
            Jesteśmy nowoczesnym <strong>biurem rachunkowym</strong> z siedzibą w Katowicach.
            <br/>
            Nieustannie wychodzimy naprzeciw potrzebom Twojego biznesu.
          </h1>
          <AnchorLink
            href="#services"
            className="btn btn-primary btn-lg hero-btn"
          >
            Sprawdź ofertę
          </AnchorLink>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 my-5 about">
            <h1 id="o-nas">O nas</h1>
            <p style={{overflow: 'auto'}}>
            <img src={finances} alt=""/>
              Biuro rachunkowe <strong>PROMO TAX</strong> z siedzibą w Katowicach posiada
              15letnie doświadczenie w prowadzeniu księgowości oraz kadr firm
              zarówno handlowych, produkcyjnych jak i usługowych i stowarzyszeń.
              <br/>
              <br/>
              PROMO TAX posiada licencję Ministra Finansów na usługowe
              prowadzenie ksiąg rachunkowych, a ponad to wciąż się rozwija i
              poszerza swoją wiedzę, aby świadczyć usługi na <strong>najwyższym
              poziomie</strong>.
            </p>
          </div>
        </div>
      </div>
      <div className="container services" id="services">
        <div className="row my-5">
          <div className="col-12">
            <h1 className="text-center mb-3">Nasze usługi</h1>
          </div>
          <div className="col service">
            <div className="services-img">
              <img src={serviceImg1} alt="" />
            </div>
            <h3 className="text-center">KSIĘGOWOŚĆ</h3>
            <p className="text-justify">
              Świadczymy kompleksowe usługi księgowe i podatkowe dla
              przedsiębiorstw, osób fizycznych prowadzących działalność
              gospodarczą, a także dla fundacji i stowarzyszeń.
            </p>
          </div>
          <div className="col service">
            <div className="services-img">
              <img src={serviceImg2} alt="" />
            </div>
            <h3 className="text-center">KADRY I PŁACE</h3>
            <p className="text-justify">
              W ramach tej usługi naliczamy wynagrodzenia, rozliczamy podatki
              oraz składki na ubezpieczenia społeczne.
            </p>
          </div>
          <div className="col service">
            <div className="services-img">
              <img src={serviceImg4} alt="" />
            </div>
            <h3 className="text-center">DORADZTWO BIZNESOWE</h3>
            <p className="text-justify">
              Służymy pomocą przy zakładaniu i rejestracji działalności
              gospodarczej, pomagamy wypełnić zgłoszenia aktualizacyjne i
              identyfikacyjne oraz inne niezbędne dokumenty.
            </p>
          </div>
          <div className="col service">
            <div className="services-img">
              <img src={serviceImg5} alt="" />
            </div>
            <h3 className="text-center">DORADZTWO PODATKOWE</h3>
            <p className="text-justify">
              W skład tej usługi wchodzi między innymi pomoc w wypełnianiu
              deklaracje podatkowych oraz rozliczenia publicznoprawne.
            </p>
          </div>

          <div className="col service">
            <div className="services-img">
              <img src={serviceImg3} alt="" />
            </div>
            <h3 className="text-center">PEŁNE WSPARCIE</h3>
            <p className="text-justify">
              Zapewniamy pomoc i wsparcie w każdej sytuacji, by zapewnić
              klientowi maksymalny komfort z prowadzenia działalności
              gospodarzej.
            </p>
          </div>
          <div className="col-12 text-center my-5">
            <Link to="/zakres-uslug" className="btn btn-primary btn-lg">
              ZOBACZ WIĘCEJ
            </Link>
          </div>
        </div>
      </div>
      <Parallax styleOuter={{maxHeight: '400px', overflow: 'hidden'}} y={[-50, -20]} tagOuter="figure">
        <Img fluid={imageSizes['fot-1']} />
      </Parallax>
      <div className="container my-5">
        <div className="row ask-details">
          <div className="col-12">
            <h1 className="text-center mb-4">Zapytaj o szczegóły</h1>
          </div>
          <div className="col-12 col-md-4 ask-details-left">
            <p>
              PROMO TAX Sp. z o.o, <br /> Ul. Misjonarzy Oblatów 20A <br />{' '}
              40-129 Katowice <br />
              NIP: 6342859579
            </p>
          </div>
          <div className="col-12 col-md-4 ask-details-center">
            <p>
              Do każdego klienta ofertę dopasowujemy <strong>indywidualnie</strong> ustalając
              zakres oraz koszt wybranych usług.
            </p>
            <Link to="/kontakt" className="btn btn-primary btn-lg my-3">
              Napisz do nas
            </Link>
          </div>
          <div className="col-12 col-md-4 ask-details-right">
            <p>
              Email: <a href="mailto:biuro@promotax.pl">biuro@promotax.pl</a>
              <br /> Tel. <a href="tel:+48794554420">794 554 420</a><br/>
              <a className="fb-link" href="https://www.facebook.com/promotaxbiurorachunkowe/">
                <Icon name="facebook"/>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div style={{ height: '60px', backgroundColor: '#69296c' }} />
      <div className="container mw-1200 mb-3">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center my-5">Aktualności</h1>
          </div>
        </div>
        <ArticleCarousel />
      </div>
      {typeof window !== 'undefined' && (
        <Suspense fallback={<div>Ładowanie...</div>}>
          <GoogleMap />
        </Suspense>
      )}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    allFile(filter: { absolutePath: { regex: "/index/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
