import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import logo from '../../../static/img/og-image.jpg'

const Meta = ({ site, title, ogdescription, image, description = '' }) => {
  const siteTitle = get(site, 'title')
  title = title ? `${title} | ${siteTitle}` : siteTitle
  ogdescription = ogdescription || get(site, 'description')
  image = image || logo;
  description = description.length ? description : ogdescription;
  return (
    <Helmet
      title={title}
      meta={[
        { property: 'og:title', content: title },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: ogdescription,
        },
        {
          property: 'description',
          content: description,
        },
        {
          property: 'og:url',
          content: `${get(site, 'siteUrl')}`,
        },
        {
          property: 'og:image',
          content: image,
        },
      ]}
    />
  )
}
export default Meta
