import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = ({ author, title }) => (
  <div className="footer mt-5">
    <div className="container">
      <hr className="border-primary" />
      <p className="text-center">
        Promotax Sp. z o.o. Copyright © {new Date().getFullYear()}
      </p>
    </div>
  </div>
)

export default Footer
