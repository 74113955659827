import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './ArticleCarousel.scss'

import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import Slider from 'react-slick'
import Card from '../CardBox/CardBox'

const Carousel = ({ data }) => (
  <StaticQuery
    query={graphql`
      query CarouselQuery {
        allMarkdownRemark {
          edges {
            node {
              id
              frontmatter {
                title
                path
                description
                category
                image {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const slides = data.allMarkdownRemark.edges.map(item => {
        return (
          <div
            className="col-12 d-flex flex-fill flex-column"
            key={item.node.id}
          >
            <Card className="flex-fill" data={item.node.frontmatter} />
          </div>
        )
      })

      const mobile = (typeof window !== 'undefined' && window.innerWidth) < 768

      var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: mobile ? 1 : 3,
        slidesToScroll: 1,
      }
      return <Slider {...settings}>{slides}</Slider>
    }}
  />
)

export default Carousel
