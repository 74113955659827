import React from 'react'
import { Card, Button } from 'react-bootstrap'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import './CardBox.scss'

const CardBox = props => {
  const { title, image, description, path } = props.data
  return (
    <Card>
      <div className="card-image">
        <Img fluid={image.childImageSharp.fluid} />
      </div>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text className="text-justify">{description}</Card.Text>
        <Link to={path} className="btn btn-primary">
          Czytaj dalej
        </Link>
      </Card.Body>
    </Card>
  )
}

export default CardBox
